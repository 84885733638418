
import { globalVar } from '~/utils/globalVar.js'
export default {
    name: 'ThemeMode',
    data: () => ({
        openThemeBtn: false,
        globalVar,
        displaySwitchThemeModeBtn: false,
        themeValue: 'theme-2',
    }),
    mounted() {
        if (window) {
            const hostname = window?.location?.hostname

            const subdomain = this.getSubdomainFromHostname(hostname)
            if (subdomain) {
                this.themeValue = subdomain
            }

            if (hostname?.endsWith('localhost')) {
                this.displaySwitchThemeModeBtn = true
            } else {
                const domainName = this.getDomainFromHostname(hostname)
                if (domainName === this.globalVar.devDomainName) {
                    this.displaySwitchThemeModeBtn = true
                }
            }
        }
    },
    methods: {
        changeTheme(theme) {
            if (window) {
                const themeType = theme.value
                if (this.themeValue === themeType) return

                const hostname = window?.location?.hostname

                // For Development Purpose
                if (hostname?.endsWith('localhost')) {
                    const oldUrl = window?.location?.href
                    const url = new URL(oldUrl)
                    if (theme.hasSubDomainName === false) {
                        url.hostname = `localhost`
                    } else {
                        url.hostname = `${theme.value}.localhost`
                    }
                    window.location.href = url.href
                    return
                }

                const domainName = this.getDomainFromHostname(hostname)

                // Main Theme has no sub domain name
                if (theme.hasSubDomainName === false) {
                    const oldUrl = window?.location?.href
                    const url = new URL(oldUrl)
                    url.hostname = `${globalVar.devDomainName}`
                    window.location.href = url.href
                    return
                }

                const oldUrl = window?.location?.href
                const url = new URL(oldUrl)
                url.hostname = `${theme.value}.${domainName}`
                window.location.href = url.href
            }
        },
        getDomainFromHostname(hostname) {
            const parts = hostname.split('.')
            // If the hostname is an IP address or a single name, return as is
            if (parts.length <= 1) {
                return hostname
            }
            // If the hostname is a normal domain with or without a subdomain, return the last two parts
            const domain = parts.slice(-2).join('.')
            return domain
        },
        getSubdomainFromHostname(url) {
            const parts = url.split('.')
            // Assuming the URL is always in the form of subdomain.domain.tld
            if (parts.length >= 3) {
                return parts.slice(0, -2).join('.')
            }
            return null
        },
    },
}
