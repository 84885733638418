
import fingerprint from '~/mixins/fingerprint'
import ReCaptcha from '~/mixins/recaptcha'
export default {
    name: 'Theme3LoginModal',
    mixins: [fingerprint, ReCaptcha],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        countryCode: {
            type: String,
            default: 'LA',
        },
    },
    data() {
        return {
            loginType: 'phone',
            valid: true,
            form: {
                player_id: '',
                phone: '',
                password: '',
                reg_fingerprint: '',
            },
            showPassword: false,
            showLoginForm: true,
            loading: false,
            country: '',
            rememberMe: true,
        }
    },
    computed: {
        modal: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
    async mounted() {
        const rememberUsername = this.$cookie.get('un')
        const rememberPhone = this.$cookie.get('pn')
        const loginOption = this.$cookie.get('login_option')

        if (rememberUsername) this.form.player_id = rememberUsername
        if (rememberPhone) this.form.phone = rememberPhone
        if (loginOption && ['phone', 'username'].includes(loginOption))
            this.loginType = loginOption

        this.form.reg_fingerprint = await this.visitorId()

        this.country =
            this.countryCode === 'GO'
                ? this?.$cookie?.get('global_reg')
                : this.countryCode
    },
    methods: {
        onCloseModal() {
            this.modal = false
            this.$emit('onCloseModal')
        },
        async login() {
            // Set login option, for next Login
            this.$cookie.set('login_option', this.loginType)

            this.loading = true

            await this.$refs.form.validate()

            if (this.valid) {
                try {
                    if (this.loginType === 'username') {
                        delete this.form.phone
                    } else if (this.loginType === 'phone') {
                        delete this.form.player_id
                    } else {
                        return this.$toast.success('Wrong login option')
                    }
                    this.form.rememberMe = this.rememberMe
                    this.form.recaptchaToken = await this.getRecaptchaToken()
                    const response = await this.$store.dispatch(
                        'auth/login',
                        this.form
                    )
                    if (!response) {
                        this.resetRecaptcha()
                    }
                } catch (error) {}
            }
            this.loading = false
        },
    },
}
