
import { mapGetters, mapState } from 'vuex'
import fingerprint from '~/mixins/fingerprint'
import ReCaptcha from '~/mixins/recaptcha'
import initSocket from '~/mixins/initSocket'
export default {
    name: 'Theme3LoginModal',
    mixins: [fingerprint, ReCaptcha, initSocket],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            acceptCondition: true,
            form: {
                phone: '',
                password: '',
                reg_fingerprint: '',
                language: 'th-TH',
            },
            showPassword: false,
            loading: false,
            showAccountHolderNameInstruction: false,
            accountHolderLoading: false,
            accountNameReadonly: true,
            suspendRegistrationLoading: false,
            registrationOptionLoading: false,
            currencyIsSuspended: false,
            registrationOption: 1, // Definitions: 1 = normal, 2 or 3 = no bank & account, 4 no everything about bank
            currency: [],
            isConditional: false,
        }
    },
    computed: {
        modal: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
        ...mapState({
            supportCountryAndLanguage: (state) =>
                state.settings.supportCountryAndLanguage,
        }),
        ...mapGetters({
            banks: 'banks/banks',
            supportCountries: 'themes/settings/supportCountry',
        }),
        formRule() {
            return {
                password: [
                    (v) => !!v || this.tl('passwordIsRequired'),
                    (v) =>
                        (v && v.length >= 6) || this.tl('passwordGreaterThan'),
                ],
                currency: [(v) => !!v || `${this.tl('currencyIsRequired')}`],
                country: [(v) => !!v || `${this.tl('countryIsRequired')}`],
                phone: [(v) => !!v || `${this.tl('phoneNumberIsRequired')}`],
                bank_id: [(v) => !!v || `${this.tl('bankIsRequired')}`],
                bank_account_number: [
                    (v) => !!v || `${this.tl('accountNumberIsRequired')}`,
                    (v) =>
                        /^[\d\s]+$/.test(v) ||
                        `${this.tl('accountNumberShouldConatainOnlyNumber')}`,
                ],

                account_holder_name: [
                    (v) => !!v || `${this.tl('accountHolderNameIsRequired')}`,
                ],
                bank_name: [(v) => !!v || `${this.tl('bankNameIsRequired')}`],
                agree: [(v) => !!v || ``],
            }
        },
    },
    watch: {
        acceptCondition(value) {
            if (value) {
                this.isConditional = false
            } else {
                this.isConditional = true
            }
        },
    },
    created() {
        if (this.supportCountries.length) {
            if (this.isInternational) {
                this.country = this.supportCountries[0]?.country_code
            } else {
                this.country = 'LA'
            }
            this.form.currency = this.supportCountries[0]?.default_currency
        }
    },
    async mounted() {
        await this.$store.dispatch('banks/banks')
        this.form.reg_fingerprint = await this.visitorId()

        const countryCode = this.$cookie.get('country_code')
        this.form.country_code = countryCode
        this.countryChange(countryCode)
    },
    methods: {
        onCloseModal() {
            this.modal = false
            this.$store.commit('themes/settings/setDisplayRegisterModal', false)
            this.$emit('onCloseModal')
            this.$emit('close')
        },
        async register() {
            if (this.acceptCondition) {
                if (this.$refs.form.validate()) {
                    try {
                        this.loading = true
                        this.form.recaptchaToken = await this.getRecaptchaToken(
                            'register'
                        )

                        this.form.registrationOption = this.registrationOption

                        const res = await this.$store.dispatch(
                            'themes/player/doRegister',
                            this.form
                        )

                        if (res) {
                            this.modal = false
                            this.$emit('onCloseModal')
                            this.$store.commit(
                                'themes/settings/setDisplayRegisterModal',
                                false
                            )

                            // Delay WebSocket initialization to ensure previous operations are fully completed
                            setTimeout(async () => {
                                await this.initSocketTheme()
                            }, 1000)
                            this.$router.push('/player/deposits/apply')
                        } else {
                            this.resetRecaptcha()
                        }
                        this.loading = false
                    } catch (error) {
                        this.loading = false
                    }
                }
            } else {
                this.isConditional = true
                this.$toast.error(this.tl('pleaseCheckCheckCondition'))
            }
        },
        async getAccountHolderName() {
            if (!this.form.bank_id || !this.form.bank_account_number) return
            this.accountHolderLoading = true
            const result = await this.$store.dispatch(
                'themes/player/getBankAccountHolderName',
                {
                    bankId: this.form.bank_id,
                    accountNo: this.form.bank_account_number,
                }
            )
            this.accountHolderLoading = false
            if (result) {
                if (!result.requireManualInput) {
                    this.form.account_holder_name = result?.fullName
                    this.accountNameReadonly = true
                    this.showAccountHolderNameInstruction = false
                    return
                }
                this.accountNameReadonly = false
                this.showAccountHolderNameInstruction = true
            }
            this.form.account_holder_name = ''
        },
        countryChange(e) {
            this.onChangeLanguageString(e)
            this.$store.dispatch('banks/banks', { country_code: e })
            const selectedCountry = this.supportCountryAndLanguage.find(
                (el) => el.country_code === e
            )
            if (selectedCountry) {
                const countryCurrency = selectedCountry.currency_code
                this.form.currency = countryCurrency

                // fire to check suspend and player registration status
                this.onChangeCurrency(countryCurrency)
            }
        },
        async onChangeLanguageString(code) {
            if (
                this.$cookie?.get('reg_loading') &&
                this.$cookie?.get('country_code') !== code
            ) {
                this.$store.commit('settings/changeLanguageLoading', true)
            } else {
                this.$cookie.set('reg_loading', true)
            }
            const filter = this.supportCountryAndLanguage.find(
                (el) => el.country_code === code
            )

            if (this.$cookie?.get('currency') !== filter?.currency_code) {
                this.$cookie.set('currency', filter?.currency_code)
            }

            if (this.$cookie?.get('country_code') !== code) {
                this.$cookie.set('country_code', filter.country_code)
                this.$store.commit(
                    'settings/setDefaultCurrency',
                    filter.currency_code
                )
                await this.globalBrowserDefaultLanguage()
            }

            this.$store.commit('settings/changeLanguageLoading', false)
        },
        onChangeCurrency(currency) {
            this.checkSuspendRegistration(currency)
            this.checkRegistrationSetting(currency)
        },
        async checkSuspendRegistration(currency) {
            this.suspendRegistrationLoading = true
            const response = await this.$store.dispatch(
                'themes/player/checkSuspendRegistration',
                currency
            )

            if (response) {
                const { status } = response
                this.currencyIsSuspended = status
                if (status) {
                    this.$toast.error(this.tl('registrationIsNotAvailable'))
                }
            }
            this.suspendRegistrationLoading = false
        },
        async checkRegistrationSetting(currency) {
            this.registrationOptionLoading = true
            const response = await this.$store.dispatch(
                'themes/player/checkRegistrationSetting',
                currency
            )

            if (response) {
                const { registration_type: regType } = response
                if (regType) {
                    if ([2, 3, 4].includes(~~regType)) {
                        this.accountNameReadonly = false
                    } else {
                        this.accountNameReadonly = true
                    }

                    this.registrationOption = ~~regType
                }
            }
            this.registrationOptionLoading = false
        },
        onChangeBank(bankId) {
            this.getAccountHolderName()
            const getSelectedBank = this.banks.find((el) => el.id === bankId)
            if (getSelectedBank?.supported_currency) {
                this.currency = getSelectedBank.supported_currency
            }
        },
    },
}
