
import { mapState, mapGetters } from 'vuex'
export default {
    name: 'Theme3NavBar',
    computed: {
        ...mapState({
            isLogin: (state) => state.settings.isLogin,
            player: (state) => state.player.player,
        }),
        ...mapGetters({
            headerLogo: 'cms/headerLogo',
            exchangeRates: 'themes/settings/exchangeRate',
            supportCountries: 'themes/settings/supportCountry',
        }),
        isPlayGamePage() {
            if (this.$route.path === '/play-game') return true
            return false
        },
        allowExchangeRateRequest() {
            const noThaiList = this.supportCountries.filter(
                (country) => country.country_code !== 'TH'
            )
            return noThaiList.length
        },
    },
    mounted() {
        if (this.$cookie.get('token')) {
            if (this.player.player_id) return
            this.$store.dispatch('player/profile')
            this.$store.dispatch('settings/toggleIsLogin', true)
            this.$store.dispatch('player/totalBalance')
        }
    },
    beforeDestroy() {
        this.doCancelAxiosRequest()
    },
    methods: {
        getLimitNickname(str) {
            if (!str) return null
            if (str.length <= 7) return str
            return `${str.substring(0, 7)}...`
        },
    },
}
