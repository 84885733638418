import { render, staticRenderFns } from "./NavBar.vue?vue&type=template&id=35561cda"
import script from "./NavBar.vue?vue&type=script&lang=js"
export * from "./NavBar.vue?vue&type=script&lang=js"
import style0 from "./NavBar.vue?vue&type=style&index=0&id=35561cda&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme3ComponentsExchangeRate: require('/var/app/components/Theme3/Components/ExchangeRate.vue').default,Theme3DesktopLanguageSwitcher: require('/var/app/components/Theme3/Desktop/LanguageSwitcher.vue').default,Theme3DesktopNavProfileDropdown: require('/var/app/components/Theme3/Desktop/Nav/ProfileDropdown.vue').default,Theme3Announcement: require('/var/app/components/Theme3/Announcement.vue').default})
