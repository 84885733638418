
import { mapState } from 'vuex'
export default {
    data: () => ({
        modal: false,
    }),
    computed: {
        ...mapState({
            loading: (state) => state.settings.changeLanguageLoading,
        }),
    },
    watch: {
        loading(value) {
            if (value) {
                this.modal = true
                // window.location.reload()
            } else {
                this.modal = false
            }
        },
    },
}
