
export default {
    name: 'GameSubmenu',
    props: {
        submenu: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    data: () => {
        return {
            hover: false,
            submenuSize: 'auto',
            windowSize: {
                x: 0,
                y: 0,
            },
            failed_image: false,
        }
    },
    computed: {
        currentSubmenuIndex() {
            return this.$store.state.navbar.currentSubmenuIndex
        },
        cPicture() {
            return this.failed_image
                ? this.noImageUri
                : `${this.$config.BUCKET}/assets/cms/menubar_providers/${this.submenu.image}`
        },
    },
    watch: {
        'windowSize.x'(value) {
            if (value > 1268 && value < 1700) {
                // ? window width size is less than 1268, called phone screen
                this.submenuSize = '250px'
            } else if (value >= 1700) {
                // ? window which has width's size is in range (2000, 1700), desktop screen but medium screen
                this.submenuSize = '250px'
            } else {
                this.submenuSize = '180px'
            }
        },
    },
    methods: {
        menuMouseOver() {
            this.$store.dispatch('navbar/submenuIndex', this.submenu.index)
        },
        menuMouseLeave() {
            this.$store.dispatch('navbar/submenuIndex', null)
        },
        onResize() {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },
        onImageError() {
            this.failed_image = true
        },
    },
}
