
import { mapGetters, mapState } from 'vuex'
export default {
    name: 'DownloadBanner',
    props: {
        downloadButtonColor: {
            type: String,
            default: '',
        },
        downloadButtonClass: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        hostname: null,
    }),
    computed: {
        ...mapState({
            downloadInterval: (state) => state.settings.downloadInterval,
        }),
        ...mapGetters({
            headerLogo: 'cms/headerLogo',
        }),
    },
    mounted() {
        this.hostname = window.location.hostname

        setTimeout(() => {
            this.$store.commit('settings/setDownloadInterval', false)
        }, 10000) // after 10 seconds close the banner

        // TODO: don't display Download Banner in Development Mode
        if (!this.$config.PRODUCTION) {
            this.$store.commit('settings/setDownloadInterval', false)
        }
    },
    methods: {
        closeBanner() {
            clearTimeout()
            this.$store.commit('settings/setDownloadInterval', false)
        },
    },
}
