
import { mapState } from 'vuex'
export default {
    name: 'AnnouncementText',
    computed: {
        announcements() {
            let anouncement = ''
            const anouns = this.$store.state.cms.announcements

            let idx = 0
            for (const a of this.getVisibleItems(anouns)) {
                idx += 1
                anouncement += `${idx}) ${this.getLocaleLang(a.description)} `
            }
            return anouncement
        },
        ...mapState({
            defaultCurrency: (state) => state.settings.defaultCurrency,
        }),
    },
    mounted() {
        this.$store.dispatch('cms/announcement', {
            currency: this.defaultCurrency,
        })
    },
}
